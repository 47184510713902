<template>
  <div class="registration-addition">
    <form
      class="registration-addition-addition__form"
      action=""
      @submit.prevent="registrationAddSubmit"
    >
      <div class="registration-addition__item">
        <label for="">Имя</label>
        <b-input
          v-model="name"
          type="text"
          class="registration-addition__input main-input"
          placeholder="Введите Имя"
          required
        />
      </div>
      <div class="registration-addition__item">
        <label for="">Фамилия</label>
        <b-input
          v-model="lastName"
          type="text"
          class="registration-addition__input main-input"
          placeholder="Введите Фамилия"
          required
        />
      </div>
      <div class="registration-addition__item">
        <label for="">Должность</label>
        <!--        <b-input-->
        <!--          type="text"-->
        <!--          v-model="position"-->
        <!--          class="registration-addition__input main-input"-->
        <!--          placeholder="Введите должность"-->
        <!--          required-->
        <!--        />-->
        <b-form-select
          v-if="positionsProducts"
          v-model="position"
          class="registration-addition__input main-input"
          text-field="title"
          value-field="id"
          :options="positionsProducts.data.data"
        />
      </div>
      <div class="registration-addition__item">
        <label for="">Номер телефона</label>
        <vue-tel-input
          v-model="phone"
          type="number"
          class="registration-addition__input main-input input_tel"
          placeholder="Введите номер телефона"
          required
        />
      </div>
      <button type="submit" class="registration-addition__btn main-btn">
        Зарегистрироваться
        <b-icon class="registration-addition__btn-icon" icon="arrow-right" />
      </button>
    </form>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapState } from "vuex";
export default {
  data() {
    return {
      iconShow: true,
      name: "",
      lastName: "",
      position: "",
      phone: "",
    };
  },
  methods: {
    async registrationAddSubmit() {
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("surname", this.lastName);
      formData.append("position_id", 1);
      formData.append("phone", this.phone);
      if (this.registration) {
        formData.append("password", this.registration.password);
        formData.append("email", this.registration.email);
      }
      await this.$api
        .post("/web/register-employee", formData)
        .then((response) => {
          Cookies.set("employee_token", response.data.data.token);
          this.$router.push("/");
        })
        .catch((e) => {
          // this.$toast.error(e.response.data);
          this.$store.commit("setToastData", {
            text: e.response.data.message,
            position: "error",
          });
          this.$toast.error(<toast-block />);
          if (
            e.message.split(" ").reverse()[0] === 500 ||
            e.message.split(" ").reverse()[0] === 401
          ) {
            this.$router.push(`/error/${e.message.split(" ").reverse()[0]}`);
          }
        });
    },
  },
  computed: {
    ...mapState({
      registration: (state) => state.registration,
      positionsProducts: (state) => state.positionsProducts,
    }),
  },
  async created() {
    if (!this.registration) {
      this.$router.push("/auth/registration");
    }
    await this.$store.dispatch("getPositionsProducts");
  },
};
</script>

<style scoped>
.registration-addition {
  margin: 40px 0 30px;
  width: 100%;
}
.registration-addition__item {
  width: 100%;
  position: relative;
}
.registration-addition__btn {
  position: relative;
  margin: 10px 0 0 0;
}
.registration-addition__btn-icon {
  position: absolute;
  right: 20px;
}
</style>
